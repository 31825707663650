<template>
  <div id="parkingRecord">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>停车记录管理</el-breadcrumb-item>
      <el-breadcrumb-item>停车记录列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
            </el-input>
          </li>
          <li>
            <span class="label">停车场:</span>
            <el-select
              v-model="filtrate.parkingLotId"
              filterable
              placeholder="请选择"
              clearable
              @change="
                getUserList();
                getList(1);
              "
            >
              <el-option
                v-for="item in parkingLotList"
                :key="item.parkingLotId"
                :label="item.parkingLotName"
                :value="item.parkingLotId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">停车状态:</span>
            <el-select
              v-model="filtrate.parkingStatus"
              placeholder="请选择"
              clearable
              @change="getList(1)"
            >
              <el-option
                v-for="item in select_parkingStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">不同车牌号:</span>
            <el-select
              v-model="filtrate.plateNumDiff"
              placeholder="请选择"
              clearable
              @change="getList(1)"
            >
              <el-option
                v-for="item in select_whether"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">是否无牌车:</span>
            <el-select
              v-model="filtrate.unlicensed"
              placeholder="请选择"
              clearable
              @change="getList(1)"
            >
              <el-option
                v-for="item in select_whether"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">离场类型:</span>
            <el-select
              v-model="filtrate.departureType"
              placeholder="请选择"
              clearable
              @change="getList(1)"
            >
              <el-option
                v-for="item in select_departureType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </li>
          <li
            v-if="
              filtrate.departureType === 4 &&
              user.authorities.includes('WEB_ADMIN')
            "
          >
            <span class="label">操作账号:</span>
            <el-select
              v-model="filtrate.forceOutUserId"
              placeholder="请选择"
              filterable
              clearable
              @change="getList(1)"
            >
              <el-option
                v-for="item in forceOutUserList"
                :key="item.userId"
                :label="item.username"
                :value="item.userId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">开始时间:</span>
            <el-date-picker
              v-model="filtrate.startTime"
              type="datetime"
              placeholder="选择开始时间"
              @change="getList(1)"
            >
            </el-date-picker>
          </li>
          <li>
            <span class="label">结束时间:</span>
            <el-date-picker
              v-model="filtrate.endTime"
              type="datetime"
              placeholder="选择结束时间"
              @change="getList(1)"
            >
            </el-date-picker>
          </li>
          <ul>
            <li>
              <el-button type="primary" size="medium" @click="getList(1)"
                >查询
              </el-button>
            </li>
            <li>
              <el-button class="resetBtn" size="medium" @click="reset"
                >重置
              </el-button>
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <el-button
              type="primary"
              @click="exportExcel"
              v-if="buttonList.includes('parkingRecord-export')"
              :loading="exportLoading"
              >导出报表
            </el-button>
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
        row-key="parkingRecordId"
        lazy
        :load="load"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        :key="table.loading"
      >
        <el-table-column label="车牌号1" align="center">
          <template #default="scope">
            <span :class="{ differentPlateNum: !scope.row.isSamePlateNum }">{{
              scope.row.plateNum
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="车牌号2" align="center">
          <template #default="scope">
            <span :class="{ differentPlateNum: !scope.row.isSamePlateNum }">{{
              scope.row.plateExtNum
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="parkingLotName" label="停车场" align="center">
        </el-table-column>
        <el-table-column prop="inChannelName" label="入口" align="center">
        </el-table-column>
        <el-table-column prop="arrivalTime" label="入场时间" align="center">
        </el-table-column>
        <el-table-column label="入场图片" align="center">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.arrivalImage"
              style="width: 70px; height: 70px"
              :src="scope.row.arrivalImage"
              fit="contain"
              :preview-src-list="[scope.row.arrivalImage]"
            ></el-image>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column prop="outChannelName" label="出口" align="center">
        </el-table-column>
        <el-table-column prop="departureTime" label="出场时间" align="center">
        </el-table-column>
        <el-table-column label="出场图片" align="center">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.departureImage"
              style="width: 70px; height: 70px"
              :src="scope.row.departureImage"
              fit="contain"
              :preview-src-list="[scope.row.departureImage]"
            ></el-image>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="departureType2"
          label="离场类型"
          align="center"
          v-if="user && user.authorities.includes('WEB_ADMIN')"
        >
        </el-table-column>
        <el-table-column
          v-else
          prop="departureType"
          label="离场类型"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="unlicensed" label="无牌车" align="center">
        </el-table-column>
        <el-table-column prop="parkingStatus" label="停车状态" align="center">
        </el-table-column>
        <el-table-column prop="parkingTime" label="停车时长" align="center">
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="getRecordDetail(scope.row.parkingRecordId)"
              v-if="buttonList.includes('parkingRecord-recordDetail')"
              >记录详情
            </el-button>
            <el-button
              type="text"
              @click="getOrderParkingInfo(scope.row.parkingRecordId)"
              v-if="buttonList.includes('parkingRecord-orderParkingInfo')"
              >订单明细
            </el-button>
            <el-button
              type="text"
              @click="getOrder(scope.row.parkingRecordId)"
              v-if="buttonList.includes('parkingRecord-order')"
              >关联订单
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
    <record-dialog ref="recordDialog" />
    <order-parking-info ref="orderParkingInfo" />
    <order-dialog ref="orderDialog" />
  </div>
</template>
<script>
export default {
  components: {
    recordDialog: () => import("./recordDialog.vue"),
    orderParkingInfo: () => import("./orderParkingInfo.vue"),
    orderDialog: () => import("./orderDialog.vue"),
  },
  computed: {
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
    user() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      filtrate: {
        keyword: "",
        parkingLotId: "",
        parkingStatus: "",
        plateNumDiff: "",
        unlicensed: "",
        departureType: "",
        forceOutUserId: "",
        startTime: this.$moment().subtract(14, "days"),
        endTime: new Date(),
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
      forceOutUserList: [],
      exportLoading: false,
    };
  },
  created() {
    if (this.$route.params.plateNum) {
      this.filtrate.keyword = this.$route.params.plateNum;
    }
    this.getUserList();
    this.getList();
  },
  methods: {
    async exportExcel() {
      if (!this.filtrate.parkingLotId) {
        this.$message.warning("请选择停车场");
        return;
      }
      try {
        this.exportLoading = true;
        await this.$http.post(
          "/parking/record/export/record",
          {
            ...this.filtrate,
            parkingStatus:
              this.filtrate.parkingStatus === ""
                ? null
                : this.filtrate.parkingStatus,
            unlicensed:
              this.filtrate.unlicensed === "" ? null : this.filtrate.unlicensed,
            departureType:
              this.filtrate.departureType === ""
                ? null
                : this.filtrate.departureType,
            plateNumDiff:
              this.filtrate.plateNumDiff === ""
                ? null
                : this.filtrate.plateNumDiff,
          },
          {
            responseType: "blob",
            timeout: 0,
          }
        );
      } catch (err) {
        this.$message.error("导出失败");
      } finally {
        this.exportLoading = false;
      }
    },
    getRecordDetail(parkingRecordId) {
      this.$refs.recordDialog.parkingRecordId = parkingRecordId;
      this.$refs.recordDialog.show = true;
    },
    getOrderParkingInfo(parkingRecordId) {
      this.$refs.orderParkingInfo.parkingRecordId = parkingRecordId;
      this.$refs.orderParkingInfo.show = true;
    },
    getOrder(parkingRecordId) {
      this.$refs.orderDialog.parkingRecordId = parkingRecordId;
      this.$refs.orderDialog.show = true;
    },
    reset() {
      this.filtrate = {
        keyword: "",
        parkingLotId: "",
        parkingStatus: "",
        plateNumDiff: "",
        unlicensed: "",
        departureType: "",
        startTime: "",
        endTime: "",
        forceOutUserId: "",
      };
      this.getList(1);
    },
    async load(tree, treeNode, resolve) {
      let res = await this.$http.get(
        `/parking/record/list/${tree.parkingRecordId}/child`
      );
      if (res.code === 0) {
        for (let item of res.data) {
          item.hasChildren = item.hasChildren === "是";
          item.inChannelName = item.inChannelName ? item.inChannelName : "--";
          item.outChannelName = item.outChannelName
            ? item.outChannelName
            : "--";
          item.departureType = item.departureType ? item.departureType : "--";
          item.departureTime = item.departureTime
            ? this.$moment(item.departureTime).format("yyyy-MM-DD HH:mm:ss")
            : "--";
          item.arrivalTime = item.arrivalTime
            ? this.$moment(item.arrivalTime).format("yyyy-MM-DD HH:mm:ss")
            : "--";
          if (!item.plateNum || !item.plateExtNum) {
            item.isSamePlateNum = true;
          } else {
            item.isSamePlateNum = item.plateNum === item.plateExtNum;
          }
        }
        resolve(res.data);
      } else {
        this.$message.error(res.msg);
      }
    },
    async getUserList() {
      if (this.user && this.user.authorities.includes("WEB_ADMIN")) {
        try {
          let res = await this.$http.post("/web/user/list/all", {
            parkingLotId: this.filtrate.parkingLotId,
          });
          if (res.code === 0) {
            this.forceOutUserList = res.data;
          } else {
            this.$message.error(res.msg);
          }
        } catch (err) {
          this.$message.error(err);
        }
      }
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/parking/record/list", {
          pageNum: page,
          pageSize: this.table.pageSize,
          ...this.filtrate,
          parkingStatus:
            this.filtrate.parkingStatus === ""
              ? null
              : this.filtrate.parkingStatus,
          unlicensed:
            this.filtrate.unlicensed === "" ? null : this.filtrate.unlicensed,
          departureType: !this.filtrate.departureType
            ? this.filtrate.parkingStatus === 4
              ? 4
              : null
            : this.filtrate.departureType,
          plateNumDiff:
            this.filtrate.plateNumDiff === ""
              ? null
              : this.filtrate.plateNumDiff,
          forceOutUserId:
            this.filtrate.forceOutUserId === ""
              ? null
              : this.filtrate.forceOutUserId,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.hasChildren = item.hasChildren === "是";
            item.inChannelName = item.inChannelName ? item.inChannelName : "--";
            item.outChannelName = item.outChannelName
              ? item.outChannelName
              : "--";
            item.departureType2 = item.departureType
              ? item.departureType +
                (item.departureType === "手动强制出场"
                  ? item.username
                    ? `--${item.username}`
                    : "/--"
                  : "")
              : "--";
            item.departureTime = item.departureTime
              ? this.$moment(item.departureTime).format("yyyy-MM-DD HH:mm:ss")
              : "--";
            item.arrivalTime = item.arrivalTime
              ? this.$moment(item.arrivalTime).format("yyyy-MM-DD HH:mm:ss")
              : "--";
            if (!item.plateNum || !item.plateExtNum) {
              item.isSamePlateNum = true;
            } else {
              item.isSamePlateNum = item.plateNum === item.plateExtNum;
            }
          }
          this.table.tableData = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#parkingRecord {
  .el-table {
    .el-table__body-wrapper {
      .el-table__body {
        tbody {
          .el-table__row {
            .cell {
              .el-image {
                width: 53px;
                height: 38px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
